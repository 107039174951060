<template>
  <div class="trainingAll">
    <div class="trainingFirst">
      <div class="trainingFirstLeft">
        <div class="trainingFirstLeft1">
          <h1>安全培训服务</h1>
          <p>
            御之安科技学院：面对国内安全人才巨大稀缺的现状，以培育产业升级安全技术和管理人才为己任，凭借御之安科技自身的安全实践与积累，将安全人才培育落实体系化、规范化、常规化，以在线课堂和面授课堂培训和认证的形式向全社会开放。
          </p>
        </div>
      </div>
    </div>
    <div class="trainingSecond">
      <div class="trainingSecond1 wow fadeInUp">
        <img src="../../../../assets/img/sofaFW/3.jpg" alt="" />
      </div>
    </div>
    <div class="trainingThird wow fadeInUp">
      <div class="trainingThirdLeft">
        <img src="../../../../assets/img/sofaFW/2.webp" alt="" />
      </div>
      <div class="trainingThirdRight">
        <p>
          我们有丰富的讲师团队,包括名校学生,大厂员工,资深专家,也包括0基础转行成功现在成为安全大牛的讲师,我们以学员为本,无论你是在校学生还是社会人士,无论你的年龄,学习网安的目的,想要达成的目标,我们会一对一答疑并制定个性化的培训计划,做最了解学员的培训机构
        </p>
        <p>
          • 授课讲师经过专业评审认证；<br />
          • 课程经过专家团队审核， 并定期校验；<br />
          • 支持认证随报随考，每个考试场地都经过御之安科技审核考察，
          保证认证公正、公平
        </p>
      </div>
    </div>
    <div class="trainingFourth">
      <h2>御之安-安全培训认证体系规划全景图</h2>
      <div class="trainingFourth1 wow fadeInUp">
        <img src="../../../../assets/img/sofaFW/4.jpeg" alt="" />
      </div>
    </div>
    <div class="trainingFourth">
      <h2>御之安-安全人才培养生态体系</h2>
      <div class="trainingFourth1 wow fadeInUp">
        <img src="../../../../assets/img/sofaFW/6.jpg" alt="" />
      </div>
    </div>
    <!-- <div class="fifth">
      <h1>安全培训</h1>
      <div class="fifth1">
        <img src="../../../../assets/img/gr/gr.jpg" alt="" />
        <div class="fifth1Right">
          <h2>
            我们同样提供针对个人的安全培训 无论你是在校学生还是社会人士
            无论你是提升自己还是转行就业
            我们能通过专业的培训祝你实现黑客(高薪)梦想
          </h2>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="课程安排" name="first">
              <p class="fifth1Right2">web安全</p>
              <p class="fifth1Right2">软件逆向</p>
              <p class="fifth1Right2">二进制漏洞利用</p>
              <p class="fifth1Right2">渗透测试</p>
              <p class="fifth1Right2">CTF专项</p>
              <p class="fifth1Right2">安全运维</p>
              <p class="fifth1Right2">安全开发</p>
            </el-tab-pane>
            <el-tab-pane label="为什么选择我们" name="second">
              <p class="fifth1Right3">
                我们有丰富的讲师团队,包括名校学生,大厂员工,资深专家,也包括0基础转行成功现在成为安全大牛的讲师,我们以学员为本,无论你是在校学生还是社会人士,无论你的年龄,学习网安的目的,想要达成的目标,我们会一对一答疑并制定个性化的培训计划,做最了解学员的培训机构
              </p>
            </el-tab-pane>
            <el-tab-pane label="我们的资源" name="third">
              <p class="fifth1Right4">专属教师一对一答疑</p>
              <p class="fifth1Right4">个性化课程定制</p>
              <p class="fifth1Right4">职业生涯规划</p>
              <p class="fifth1Right4">专属私人靶场</p>
              <p class="fifth1Right4">VIP社区</p>
              <p class="fifth1Right4">国家级安全攻防项目</p>
              <p class="fifth1Right4">优秀学员包就业</p>
              <p class="fifth1Right4">圈内大牛专家人脉资源</p>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  data() {
    return {};
  },
  mounted() {
    //第一种写法，可以设置wow中属性
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      var wow = new WOW({
        boxClass: "wow", ///动画元件css类（默认为wow）
        animateClass: "animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      });
      wow.init();
    });
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style lang="less" scoped>
.trainingAll {
  width: 100%;
  .trainingFirst {
    background-image: url(../../../../assets/img/sofaFW/1.gif);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 200px;
    .trainingFirstLeft {
      width: 100%;
      margin: 0 auto;
      background-image: url(../../../../assets/img/aboutus/3.png);
      background-size: 600px 100%;
      background-repeat: no-repeat;
      background-position: center center;
      padding: 120px;
      .trainingFirstLeft1{
        width: 300px;
        margin: 0 auto;
        h1 {
          font-size: 24px;
          color: #fff;
          margin-bottom: 30px;
        }
        p {
          
          font-size: 20px;
          margin-top: 10px;
          color: #fff;
        }
      }
    }
  }
  .trainingSecond {
    margin-top: 40px;
    background-color: #f1f5ff;
    .trainingSecond1 {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      img {
        text-align: center;
      }
    }
  }
  .trainingThird {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1f5ff;
    .trainingThirdLeft {
      img {
        width: 458px;
        height: 458px;
      }
    }
    .trainingThirdRight {
      width: 32%;
      p {
        font-size: 16px;
        font-family: "微软雅黑";
        color: #51565d;
        margin-top: 10px;
        line-height: 1.8;
      }
    }
  }
  .trainingFourth {
    margin-top: 40px;
    background-color: #f1f5ff;
    h2 {
      padding-top: 30px;
      text-align: center;
      margin-bottom: 20px;
    }
    .trainingFourth1 {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      img {
        text-align: center;
      }
    }
  }
  .fifth {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 45px;
      font-weight: 900;
      margin-bottom: 35px;
    }
    .fifth1 {
      width: 100%;
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .fifth1Left {
      width: 20%;
      img {
        width: 355px;
        height: 600px;
      }
    }
    .fifth1Right {
      width: 60%;
      margin-left: 30px;
      .fifth1Right1 {
        color: #e21f36;
        font-weight: 600;
        padding-bottom: 10px;
        border-bottom: 1px solid #515151;
      }
      h2 {
        font-size: 35px;
        margin-bottom: 40px;
        position: relative;
        font-weight: 600;
      }
      /deep/.el-tabs__item {
        padding: 0 27px;
        height: 40px;
        box-sizing: border-box;
        line-height: 40px;
        display: inline-block;
        list-style: none;
        font-size: 20px;
        font-weight: 500;
        color: #303133;
        position: relative;
      }
      .fifth1Right2 {
        color: #6e727f;
        font-size: 19px;
        margin-top: 10px;
      }
      .fifth1Right3 {
        color: #6e727f;
        font-size: 19px;
        margin-top: 10px;
      }
      .fifth1Right4 {
        color: #6e727f;
        font-size: 19px;
        margin-top: 10px;
      }
    }
  }
}
</style>